<template>
    <div>
        <div class="box">
            <div class="index-top">
                <img v-if="head"  :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + head">
                <div>{{business_name}}</div>
            </div>
        </div>
        <div class="container">
            <div class="container-div"  @click="scan()">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/checkh5-扫码.png" alt="">
                <p>扫码核销</p>
            </div>
            <router-link class="container-div" to="/check/scan">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/checkh5-2021120103.png" alt="">
                <p>渠道核销</p>
            </router-link>
            <router-link class="container-div" to="/check/yard">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/checkh5-2021120104.png" alt="">
                <p>订单核销</p>
            </router-link>
            <router-link class="container-div" to="/check/orderList">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/checkh5-订单.png" alt="">
                <p>订单</p>
            </router-link>
            <router-link class="container-div" to="/check/checkShop" v-if="permission.is_SeparateOrders === 0">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/checkh5-统计.png" alt="">
                <p>统计</p>
            </router-link>
            <router-link class="container-div" to="/check/reserveList">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/checkh5-订单.png" alt="">
                <p>预约订单</p>
            </router-link>
            <div class="container-div" ref="Confirm" @click="exit()">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/checkh5-退出.png" alt="">
                <p>退出登录</p>
            </div>
        </div>
    </div>
</template>
<script>
  import { globalConfig } from '../../utils/mixin'
  import wx from 'weixin-js-sdk'
  export default {
    name: 'Index',
    mixins: [globalConfig],
    components: {},
    data () {
      return {
          business_name: '',
          head: '',
          permission: [],
          accountCount: ''
      }
    },
    methods: {
      init: function () {
        this.axios.get(this.apiUrl + 'checkh5/api/init').then((response) => {
          console.log(response)
          const data = response.data
          if (data.status === 0) {
            this.$router.replace('/check/login')
          } else {
            this.business_name = data.business_name
              this.accountCount = data.accountCount
              this.permission = data.permission
              this.head = data.head
          }
        })
      },
      exit: function () {
        const that = this
        that.axios.get(that.apiUrl + 'checkh5/login/relieve').then((response) => {
          console.log(response)
          that.$router.replace('/check/login')
        })
      },
      scan: function () {
        wx.scanQRCode({
          needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            // var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
          }
        })
      }
    },
    created () {
      this.init()
    },
    mounted () {
    },
    computed: {
    }
  }
</script>
<style scoped>
    body{
        background-color: #ffffff;
        margin: 0;
        padding: 0;
    }
    .box{
        width: 100vw;
        height: 40vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/banner2022010701.png") no-repeat #FEF9F6;
        background-size: 100%;
    }
    .index-top{
        display: flex;
        align-items: center;
        margin:0 0 3vw 5vw;
        padding-top: 8vw;
    }
    .index-top img{
        width: 20vw;
        border-radius: 20vw;
    }
    .index-top div{
        margin: 0 0 0 4vw;
        font-size: 4.2vw;
        color: #fff;
        font-weight: 600;
    }
    .container{
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
    }
    .container-div{
        width: 32.5%;
        border: 1px solid #ececec;
        padding: 4vw 0;
    }
    .container-div img{
        width: 30%;
        margin: auto 35%;
    }
    .container-div p{
        font-size: 3.2vw;
        color: #666;
        text-align: center;
    }
</style>
